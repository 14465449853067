/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { string, func } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'; // Import the format function
import CUSTOM_BREAKPOINTS from '../../../../../../helpers/customBreakpoints';
import { getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useSalesforcePersonalizedContentQuery from '../../../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import { trackEvent } from '../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import useClickStreamCustomEventsTracking from '../../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import AutoFillAddressSkeleton from '../../../../GraphqlSkeletonComponents/AutoFillAddressSkeleton';

const useStyles = makeStyles((theme) => ({
    datePickerInput: {
        width: '100%',
        borderBottom: 'none',
        marginBottom: 0,
        '& input': {
            fontSize: theme.typography?.fontSize || '16px',
            border: theme.palette.gfTabDesktopInputBorder,
            backgroundColor: theme.palette.white,
            borderRadius: '3px',
            padding: '0 22px 0 10px',
            height: '46px',
            '&::placeholder': {
                color: '#666666',
                opacity: '1',
            },
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                height: '32px',
                padding: '0 22px 0 10px',
                fontSize: theme.palette?.text?.fontSize12 || '12px',
                '&:focus-visible': {
                    outline: '-webkit-focus-ring-color auto 1px',
                    borderRadius: '3px',
                },
            },
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
                fontSize: theme.palette?.text?.fontSize14 || '14px',
            },
            [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                borderRadius: '4px',
                '&:focus, &:active': {
                    borderRadius: '4px',
                    border: '1px solid #262626',
                    boxShadow: 'none',
                    outline: 0,
                },
            },

        },
        '& .MuiInputAdornment-root': {
            position: 'absolute',
            right: '0',
            '& .MuiIconButton-root:hover': {
                background: 'transparent',
            },
            '& svg': {
                [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                    width: '16px',
                    height: '16px',
                },
            },
        },

    },
    labelRoot: {
        fontSize: theme.typography?.fontSize || '16px',
        color: theme.palette.white,
        letterSpacing: '0.42px',
        zIndex: 9,
        [theme.breakpoints.down(325)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            fontSize: theme.palette?.text?.fontSize12 || '12px',
            fontWeight: 300,
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        '&:hover': {
            borderBottom: 'none',
        },
        '&$labelFocused': {
            color: theme.palette.white,
        },
    },
    labelFocused: {},
    shrink: {
        [theme.breakpoints.up(767)]: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    },
    '@keyframes fadeZoomIn': {
        '0%': { opacity: 0, transform: 'scale3d(0.3, 0.3, 0.3)' },
        '100%': { opacity: 1 },
    },
    inputMarginTop: {
        'label + &': {
            marginTop: '20px',
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                marginTop: '22px',
            },
        },
    },
    dateContainer: {
        flex: '0 1 100%',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flex: '1 0 150px',
            marginRight: '8px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            flex: '0 1 167px',
            marginRight: '16px',
            minWidth: '167px',
        },
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            marginTop: '4px',
            position: 'relative',
        },
    },
    errorStyle: {
        position: 'absolute',
        bottom: '6px',
        lineHeight: '12px',
        fontSize: '11px',
        color: theme.palette.colorAlert,
        [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            mottom: '0',
            top: '3px',
            left: '65px',
            textShadow: '0 0 2px #fff',
        },
    },
}));

const DeliveryDateField = ({
    dateValue, handleChange, deliveryDateTitle, giftFinderBodyTextColor, brandDomain,
}) => {
    const classes = useStyles();
    const isGiftFinderDeliveryDate = useSelector(getFeatureFlag('is-show-gift-finder-delivery-date'));
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [error, setError] = useState(''); // State to track error messages

    const { data, loading, variables } = useSalesforcePersonalizedContentQuery({
        queryName: ' gfDeliveryDateInput',
        interactionName: `${brandDomain} - Get Campaign - Gift Finder Delivery Date Input`,
        featureFlag: isGiftFinderDeliveryDate,
        optInHoldoutParticipation: false,
        resolveIdentity: false,
    });

    const deliveryDateInput = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;
    const deliveryDataInputEnabled = deliveryDateInput?.toLowerCase?.() === 'test';

    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: { type: 'homepage' },
    });

    useEffect(() => {
        if (typeof deliveryDateInput !== 'undefined' && !window.wuDeliveryEventFired && deliveryDateInput) {
            // GA tracking
            const eventParam = {
                eventCategory: 'Experiment',
                eventAction: 'Gift Finder Delivery Date Input',
                eventLabel: deliveryDataInputEnabled ? 'Variant' : 'Control',
                feature_detail: deliveryDataInputEnabled ? 'Variant' : 'Control',
                feature_element: 'Gift Finder Delivery Date Input',
                feature_category: 'Experiment',
                experiment_id: data?.contentSF?.campaign?.campaignResponses?.[0]?.campaignId,
            };
            dispatch(trackEvent(eventParam));
            window.wuDeliveryEventFired = true;
        }
        return () => {
            window.wuDeliveryEventFired = false;
        };
    }, [deliveryDateInput]);

    if (loading && variables?.skip) {
        return <AutoFillAddressSkeleton />;
    }

    const createEvent = (value = '', errorMsg = '') => ({
        target: { value, errorMsg },
    });

    const handleDateChange = (date) => {
        if (date instanceof Date && !Number.isNaN(date.getTime())) {
            setSelectedDate(date);
            const formattedDate = format(date, 'EEEE, MMM dd');
            handleChange('date')(createEvent(formattedDate, ''));
            setError(''); // Clear any existing error
            const eventParam = {
                eventCategory: 'Hompage',
                eventAction: 'Gift Finder Delivery Date Input',
                eventLabel: formattedDate,
                feature_detail: formattedDate,
                feature_element: 'Gift Finder Delivery Date Input',
                feature_category: 'Hompage',
                experiment_id: data?.contentSF?.campaign?.campaignResponses?.[0]?.campaignId,
            };
            dispatch(trackEvent(eventParam));
        } else {
            const errorMsg = date !== null;
            handleChange('date')(createEvent('', errorMsg));
            setError(errorMsg ? 'Invalid Date' : ''); // Set error for invalid date
        }
    };

    const calendarIcon = () => (
        <span  style={{ cursor: 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                <path d="M2.33333 6H3.5V7.2H2.33333V6ZM10.5 2.4V10.8C10.5 11.466 9.98083 12 9.33333 12H1.16667C0.857247 12 0.560501 11.8736 0.341709 11.6485C0.122916 11.4235 0 11.1183 0 10.8V2.4C0 1.74 0.525 1.2 1.16667 1.2H1.75V0H2.91667V1.2H7.58333V0H8.75V1.2H9.33333C9.64275 1.2 9.9395 1.32643 10.1583 1.55147C10.3771 1.77652 10.5 2.08174 10.5 2.4ZM1.16667 3.6H9.33333V2.4H1.16667V3.6ZM9.33333 10.8V4.8H1.16667V10.8H9.33333ZM7 7.2V6H8.16667V7.2H7ZM4.66667 7.2V6H5.83333V7.2H4.66667ZM2.33333 8.4H3.5V9.6H2.33333V8.4ZM7 9.6V8.4H8.16667V9.6H7ZM4.66667 9.6V8.4H5.83333V9.6H4.66667Z" fill="black" />
            </svg>
        </span>
    );

    return (
        deliveryDataInputEnabled  ? (
            <div className={classes.dateContainer}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        disablePast
                        fullWidth
                        variant="dialog"
                        format="MM/dd/yyyy"
                        placeholder="MM/DD/YYYY"
                        id="date-picker-inline"
                        helperText=""
                        label={deliveryDateTitle || 'Arrives by'}
                        value={selectedDate}
                        onError={(errorType) => {
                            if (errorType) {
                                setError('Select a future date'); // Fallback for handling errors
                            }
                        }}
                        onChange={(date) => {
                            handleDateChange(date); // Optionally use handleChange with the name
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: giftFinderBodyTextColor,
                            },
                            classes: {
                                shrink: classes.shrink,
                                root: `${classes.labelRoot}`,
                                focused: classes.labelFocused,
                            },
                        }}
                        keyboardIcon={calendarIcon()} // Replace with any custom icon
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                root: `${classes.datePickerInput} ${classes.inputMarginTop}`,
                            },
                        }}
                    />
                    {error && <p className={classes.errorStyle}>{error}</p>}
                </MuiPickersUtilsProvider>
            </div>
        ) : <></>
    );
};
DeliveryDateField.propTypes = {
    handleChange: func,
    dateValue: string.isRequired,
    deliveryDateTitle: string,
    giftFinderBodyTextColor: string,
    brandDomain: string.isRequired,
};
DeliveryDateField.defaultProps = {
    handleChange: () => { },
    deliveryDateTitle: 'Arrives by',
    giftFinderBodyTextColor: '',
};

export default DeliveryDateField;
